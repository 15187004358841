import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

import arrowButton from "../../../static/arrow-button.svg";
import { Partners } from "../Partners";
import {
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
  BoxTextRight,
  Text,
  Box,
  BoxColumns,
  Services,
  Card,
  CardServices,
  BannerSection,
  PopUpAviso,
} from "./styles";
import Carousel from "react-elastic-carousel";

import iconClosePopUp from "../../../static/icon-close.png";
import imgThirdSection from "../../../static/img-third-section.jpg";
import imgLogo from "../../../static/logo.svg";
import imgIconServer from "../../../static/icon-server.svg";
import imgIconMonitor from "../../../static/icon-monitor.svg";
import imgIconGears from "../../../static/icon-gears.svg";
import imgIconCloud from "../../../static/icon-cloud.svg";
import imgIconVelocimeter from "../../../static/icon-velocimeter.svg";
import imgIconLock from "../../../static/icon-lock.svg";
import imgCardTransferenciaArquivosSeguranca from "../../../static/card-transferencia-arquivos-seguranca.png";
import imgCardArquivosCriptografados from "../../../static/card-arquivos-criptografados.png";
import imgCardAutomacao from "../../../static/card-automacao.png";
import imgcardIntegracao from "../../../static/card-integracao.png";
import imgCardLgpd from "../../../static/card-lgpd.png";
import imgCardStcp from "../../../static/card-stcp.png";
import imgBgTransferenciaArquivosSeguranca from "../../../static/bg-card-transferencia-arquivos-seguranca.webp";
import imgBgTransferenciaArquivosIntegracao from "../../../static/bg-card-transferencia-arquivos-integracao.webp";
import imgBgTransferenciaArquivosCriptografados from "../../../static/bg-card-trasnferencia-arquivos-criptografados.webp";
import imgBgCorcordancia from "../../../static/bg-card-concordancia.webp";
import imgBgVantagens from "../../../static/bg-card-vantagens.webp";
import imgBgAutomacao from "../../../static/bg-card-automacao.webp";
import { SectionPartners } from "../SectionPartners";
import swal from "sweetalert";

export function Sections() {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  const getUrlParamByName = (name, url) => {
    if (!url) url = currentUrl;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const utmSourceRef =
    getUrlParamByName("utm_source") !== null
      ? getUrlParamByName("utm_source")
      : "";
  const utmMediumRef =
    getUrlParamByName("utm_medium") !== null
      ? getUrlParamByName("utm_medium")
      : "";
  const utmCampaignRef =
    getUrlParamByName("utm_campaign") !== null
      ? getUrlParamByName("utm_campaign")
      : "";
  const utmTermRef =
    getUrlParamByName("utm_term") !== null ? getUrlParamByName("utm_term") : "";
  const utmContentRef =
    getUrlParamByName("utm_content") !== null
      ? getUrlParamByName("utm_content")
      : "";

  const formElement = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = () => {
    emailjs
      .sendForm(
        "service_erh9ysq",
        "template_s5nk1v4",
        formElement.current,
        "FUtfg3KFH62CeopLQ"
      )
      .then(
        result => {
          console.log(result.text);
          swal(
            "Email enviado!",
            "Obrigado, nós recebemos sua mensagem, em breve entraremos em contato.",
            "success"
          );
          reset();
        },
        error => {
          console.log(error.text);
          swal("Falha de envio!", "Tente novamente.", "error");
        }
      );
  };

  const [title, setTitle] = useState("Transferência de arquivos");
  const [subTitle, setSubTitle] = useState(
    "O que é e como funciona o sistema da Riversoft?"
  );
  const [description, setDescription] = useState(
    "A solução para empresas que precisam enviar e receber arquivos e integrar informações entre diferentes sistemas todos os dias. O STCP (Secure Transfer Control Program) foi desenvolvido pela Riversoft para fazer isso com segurança em todas as etapas em um sistema de uso prático para quem não quer perder tempo ou dados."
  );

  const [endStrongText, setEndStrongText] = useState("");

  const [bgImage, setBgImage] = useState(imgBgTransferenciaArquivosSeguranca);

  const [indexSlideSolutions, setIndexSlideSolutions] = useState(0);

  useEffect(() => {}, [indexSlideSolutions]);

  const servicesList = [
    {
      title: "Servidor para envio de arquivos",
      iconImg: imgIconServer,
      textContent:
        "O STCP Server automatiza todo o seu processo de transferência segura de arquivos, utilizando múltiplos protocolos.",
    },
    {
      title: "Para envio de todo tipo de arquivo",
      iconImg: imgIconMonitor,
      textContent:
        "O STCP permite o envio de arquivos de qualquer tamanho, desde que suportado pelo protocolo de comunicação escolhido.",
    },
    {
      title: "Criptografia e assinatura digital",
      iconImg: imgIconLock,
      textContent:
        "O STCP Signer permite assinar e criptografar arquivos antes e após a transferência para adicionar uma camada extra de segurança e deixar o envio seguro.",
    },
    {
      title: "Gestão e auditoria",
      iconImg: imgIconCloud,
      textContent:
        "O STCP OFTP Web Admin permite acompanhar transações, históricos e fazer a gestão dos seus servidores STCP.",
    },
    {
      title: "Controle de SLA",
      iconImg: imgIconVelocimeter,
      textContent:
        "O STCP Monitor permite saber se suas transferências foram executadas corretamente. Ajudando a garantir suas entregas de SLA.",
    },
    {
      title: "Cópia e renomeio",
      iconImg: imgIconGears,
      textContent:
        "O STCPREN renomeia e copia arquivos de acordo com suas regras de negócio.",
    },
  ];

  function map() {
    return {
      __html:
        "<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3693.247402874585!2d-45.93248968557771!3d-22.230690019682463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cbc7ba33a36a61%3A0xe47e441c5da6a51e!2zUml2ZXJzb2Z0IEludGVncmHDp8OjbyBlIElub3Zhw6fDo28!5e0!3m2!1spt-BR!2sbr!4v1621022902677!5m2!1spt-BR!2sbr' width='500' height='450' style='border:0;' allowfullscreen='' loading='lazy'></iframe>",
    };
  }

  const solutionsList = [
    {
      title: "Transferência de arquivos",
      subtitle: "O que é e como funciona o sistema da Riversoft?",
      description:
        "A solução para empresas que precisam enviar e receber arquivos e integrar informações entre diferentes sistemas todos os dias. O STCP (Secure Transfer Control Program) foi desenvolvido pela Riversoft para fazer isso com segurança em todas as etapas em um sistema de uso prático para quem não quer perder tempo ou dados.",
      image: imgCardTransferenciaArquivosSeguranca,
      bgImage: imgBgTransferenciaArquivosSeguranca,
      endStrongText: "",
    },
    {
      title: "Transferência de arquivos de forma segura",
      subtitle: "Seus arquivos com o que você precisa: segurança",
      description:
        "Muitos sistemas de transferência de arquivos podem não ter o nível de segurança que a sua empresa e os seus documentos exigem. Mas o STCP da Riversoft possui criptografia em todas as etapas, desde o envio até o recebimento.",
      image: imgCardArquivosCriptografados,
      bgImage: imgBgTransferenciaArquivosCriptografados,
      endStrongText:
        "Seus arquivos só serão vistos e acessados por quem sua empresa quiser.",
    },
    {
      title: "Transferência de arquivos com integração de processos",
      subtitle: "Compatível com todos os sistemas",
      description:
        "Se a integração entre seus sistemas e o seu serviço de transferência de arquivos é um problema, ela não precisa ser mais. Com o STCP da Riversoft, a integração entre as plataformas é 100% feita por nós e tem garantia total de funcionamento.",
      image: imgcardIntegracao,
      bgImage: imgBgTransferenciaArquivosIntegracao,
      endStrongText:
        "Perder tempo e dinheiro com sistemas incompatíveis? Nunca mais.",
    },
    {
      title: "Automação de envio de arquivos",
      subTitle: "Automatize os processos na sua empresa",
      description:
        "Se a integração entre seus sistemas e o seu serviço de transferência de arquivos é um problema, ela não precisa ser mais. Com o STCP da Riversoft, a integração entre as plataformas é 100% feita por nós e tem garantia total de funcionamento.",
      image: imgCardAutomacao,
      bgImage: imgBgAutomacao,
      endStrongText:
        "Perder tempo e dinheiro com sistemas incompatíveis? Nunca mais.",
    },
    {
      title: "Concordância com LGPD",
      subtitle: "Tudo dentro e nos conformes da LGPD",
      description:
        "Lidar com informações e dados dos clientes, e também da sua própria empresa, exigem cuidado. Por isso, nossa solução de transferência de arquivos já está de acordo com todas as normas da LGPD referente ao envio, armazenamento e recebimento de dados.",
      image: imgCardLgpd,
      bgImage: imgBgCorcordancia,
      endStrongText:
        "Sua empresa mais tranquila com a LGPD? Com a Riversoft isso é possível e simples.",
    },
    {
      title: "Vantagens do STCP",
      subtitle:
        "Veja tudo o que nossa solução de gestão eletrônicade documentos faz",
      description:
        "<span style='font-size: 0.9rem'> - Rastreabilidade dos arquivos <br>- Escalabilidade <br>- Plataforma White Label para terceirização <br>- Transferência ilimitada<br> - Recuperação automática de transferência interrompida<br> - Protocolos abertos para mais liberdade de integração <br>- Conexão sem intermediários <br>- Suporte personalizado e sempre disponível</span>",
      image: imgCardStcp,
      bgImage: imgBgVantagens,
      endStrongText: "",
    },
    {
      title: "",
      subtitle: "",
      description: "",
      image: "",
      bgImage: imgBgVantagens,
      endStrongText: "",
      class: "hide",
    },
    {
      title: "",
      subtitle: "",
      description: "",
      image: "",
      bgImage: imgBgVantagens,
      endStrongText: "",
      class: "hide",
    },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, showEmptySlots: true },
    { width: 500, itemsToShow: 2, itemsToScroll: 1, showEmptySlots: true },
    { width: 700, itemsToShow: 3, itemsToScroll: 1, showEmptySlots: true },
    { width: 1150, itemsToShow: 3, itemsToScroll: 1, showEmptySlots: true },
    { width: 1450, itemsToShow: 3, itemsToScroll: 1, showEmptySlots: true },
    { width: 1750, itemsToShow: 3, itemsToScroll: 1, showEmptySlots: true },
  ];

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === 5) {
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(solutionsList.length);
    }
  };

  const [statusPopUp, setStatusPopUp] = useState("none");

  return (
    <>
      
      {
      // BANNER AVISO - POPUP CONTATO
      
      <PopUpAviso style={{ display: statusPopUp }}>
        <div className="wrapper">
          <div className="dividerUp"></div>
          <img
            className="closeIcon"
            src={iconClosePopUp}
            alt="Ícone de fechar Popup"
            onClick={() => setStatusPopUp("none")}
            aria-hidden="true"
          />
          <div className="box">
            <strong>Suporte</strong>
            <p>E-mail: suporte@riversoft.com.br</p>
            <p>Telefone: (35) 99904-7989</p>
            <br />
            <strong>Comercial</strong>
            <p>E-mail: comercial@riversoft.com.br</p>
            <p>Telefone: (35) 99136-0426</p>
          </div>
          <div className="dividerDown"></div>
        </div>
      </PopUpAviso>
      
      }

      {
      // BANNER AVISO

      <BannerSection> 
        <p className="title">
          Estaremos em recesso a partir do dia <strong>23/12/2024</strong>,
          retornando as nossas atividades em{" "}
          <strong>02/01/2025</strong>. <br />
          Durante esse período, estaremos disponíveis em regime de plantão. Entre em contato clicando <strong
            className="link"
            onClick={() => setStatusPopUp("flex")}
            aria-hidden="true"
          >aqui</strong>.
        </p>
      </BannerSection>

      // FIM BANNER AVISO
      }

      <FirstSection className="fadeIn">
        <div className="Content">
          <BoxTextRight>
            <Text width="34%">
              <h1>Transferência de arquivos com segurança e gestão simples</h1>
              <p>
                Receba e envie arquivos da sua empresa com segurança,
                escalabilidade, total integração e controle de cada byte e em
                acordo com a LGPD.
              </p>
              <a
                className="primaryButton"
                href="https://www.riversoft.com.br/solucao-transferencia-de-arquivos-stcp/"
                target="_blank"
                rel="noreferrer"
              >
                Fale com um especialista{" "}
                <img src={arrowButton} alt="Riversoft" />
              </a>
            </Text>
          </BoxTextRight>
        </div>
      </FirstSection>

      <Partners />

      <SecondSection>
        {solutionsList.map((item, index) => {
          return (
            <div
              className={`bg-img ${
                item.bgImage === bgImage ? "show-img" : "hide-img"
              }`}
            >
              <img src={item.bgImage} alt="Riversoft" />
            </div>
          );
        })}

        <div className="Content">
          <BoxColumns
            widthFraction="35% 65%"
            mediumScreenwidthFraction="1fr"
            largeScreenwidthFraction="30% 70%"
          >
            <Box>
              <Text color="#FFFFFF">
                <h2>{title}</h2>
                <p className="strong">{subTitle}</p>
                <p>{ReactHtmlParser(description)}</p>
                <strong>{endStrongText}</strong>
              </Text>
            </Box>
            <Box>
              <CardServices>
                <Carousel
                  ref={carouselRef}
                  onPrevStart={onPrevStart}
                  onNextStart={onNextStart}
                  disableArrowsOnEnd={false}
                  pagination={false}
                  breakPoints={breakPoints}
                  className="normal-carousel"
                  onChange={(currentItem, pageIndex) => {
                    setIndexSlideSolutions(pageIndex);
                    setTitle(currentItem.item.title);
                    setSubTitle(currentItem.item.subtitle);
                    setDescription(currentItem.item.description);
                    setEndStrongText(currentItem.item.endStrongText);
                    setBgImage(currentItem.item.bgImage);
                  }}
                >
                  {solutionsList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        bgImage={item.bgImage}
                        title={item.title}
                        subTitle={item.subtitle}
                        description={item.description}
                        endStrongText={item.endStrongText}
                        onClick={() => {
                          setTitle(item.title);
                          setSubTitle(item.subtitle);
                          setDescription(item.description);
                          setEndStrongText(item.endStrongText);
                          setBgImage(item.bgImage);
                        }}
                        className={`card-slide ${
                          item.class !== undefined ? item.class : ""
                        }`}
                        onKeyPress={() => {
                          setTitle(item.title);
                          setSubTitle(item.subtitle);
                          setDescription(item.description);
                          setEndStrongText(item.endStrongText);
                          setBgImage(item.bgImage);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <img
                          src={item.image}
                          alt="Riversoft"
                          title={item.title}
                        />
                        <p>{item.title}</p>
                      </div>
                    );
                  })}
                </Carousel>

                <Carousel
                  disableArrowsOnEnd={false}
                  pagination={false}
                  breakPoints={breakPoints}
                  className="responsive-carousel"
                  onChange={(currentItem, pageIndex) => {
                    setIndexSlideSolutions(pageIndex);
                    setTitle(currentItem.item.title);
                    setSubTitle(currentItem.item.subtitle);
                    setDescription(currentItem.item.description);
                    setEndStrongText(currentItem.item.endStrongText);
                    setBgImage(currentItem.item.bgImage);
                  }}
                >
                  {solutionsList.map((item, index) => {
                    if (item.class !== "hide") {
                      return (
                        <div
                          key={index}
                          bgImage={item.bgImage}
                          title={item.title}
                          subTitle={item.subtitle}
                          description={item.description}
                          endStrongText={item.endStrongText}
                          onClick={() => {
                            setTitle(item.title);
                            setSubTitle(item.subtitle);
                            setDescription(item.description);
                            setEndStrongText(item.endStrongText);
                            setBgImage(item.bgImage);
                          }}
                          className={`card-slide ${
                            item.class !== undefined ? item.class : ""
                          }`}
                          onKeyPress={() => {
                            setTitle(item.title);
                            setSubTitle(item.subtitle);
                            setDescription(item.description);
                            setEndStrongText(item.endStrongText);
                            setBgImage(item.bgImage);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <img
                            src={item.image}
                            alt="Riversoft"
                            title={item.title}
                          />
                          <p>{item.title}</p>
                        </div>
                      );
                    } else {
                      return false;
                    }
                  })}
                </Carousel>
              </CardServices>
            </Box>
          </BoxColumns>
        </div>
      </SecondSection>

      <ThirdSection id="solutions">
        <div className="Content">
          <BoxColumns widthFraction="1fr 3fr">
            <Box>
              <Text style={{ marginBottom: "2rem" }}>
                <h2>Soluções</h2>
                <p className="strong">TRANSFERÊNCIA GERENCIADA DE ARQUIVOS</p>
                <p className="medium">
                  Para todos os tipos de arquivos, para todos os tipos de
                  empresa
                </p>
                <p>
                  Nós temos diferentes tipos de STCP que podem se encaixar no
                  que a sua empresa precisa. O pagamento é simplificado, com
                  licenciamento flexível, diferentes planos de suporte,
                  manutenção, instalação e treinamento incluso.
                </p>
                <p className="strong">
                  E o uso? <br />
                  Ilimitado para transferir <br />
                  quando e o quanto precisar.
                </p>
              </Text>
            </Box>
            <Box>
              <img
                className="img-third-section"
                src={imgThirdSection}
                alt="Riversoft"
              />
            </Box>
          </BoxColumns>
          <Services>
            <BoxColumns
              widthFraction="repeat(3, 1fr)"
              mediumScreenwidthFraction="1fr 1fr"
              largeScreenwidthFraction="repeat(3, 1fr)"
            >
              {servicesList.map((item, index) => {
                return (
                  <Card key={index}>
                    <img src={item.iconImg} alt="Riversoft" />
                    <p className="strong">{item.title}</p>
                    <p>{item.textContent}</p>
                  </Card>
                );
              })}
            </BoxColumns>
          </Services>
          <BoxColumns widthFraction="2fr 1fr" id="about">
            <Box>
              <Text width="90%">
                <h2>Gestão de envio de arquivos</h2>
                <p className="strong">Prazer, somos a Riversoft</p>
                <p>
                  A Riversoft foi fundada em março de 2004 com o objetivo de
                  oferecer ao mercado produtos e serviços na área de integração
                  e desenvolvimento de software com foco no crescente mercado de
                  intercâmbio de informações entre sistemas heterogêneos e
                  empresas (EAI, EDI e B2B). <br />
                  <br />
                  Todos os produtos incorporados na solução STCP são
                  desenvolvidos, mantidos e suportados pela Riversoft. <br />
                  <br />
                  Sendo assim, podemos oferecer benefícios financeiros
                  significativos aos projetos de intercâmbio de arquivos e
                  integração de processos.
                </p>
              </Text>
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={imgLogo} alt="Riversoft" className="logo" />
            </Box>
          </BoxColumns>
        </div>
      </ThirdSection>

      <FourthSection id="partners">
        <div className="Content">
          <BoxColumns widthFraction="1fr 2fr">
            <Box>
              <Text width="87%">
                <h2>Parcerias</h2>
                <p className="strong">Conheça os parceiros Riversoft</p>
                <p>
                  Sempre com foco no cliente, além de uma equipe capacitada e
                  envolvida, contamos estrategicamente com parceiros que ocupam
                  a vanguarda da tecnologia, oferecendo valor inigualável à
                  nossos clientes.
                </p>
                <a
                  className="thirdButton"
                  href="https://www.riversoft.com.br/solucao-transferencia-de-arquivos-stcp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Agende um teste grátis{" "}
                  <img src={arrowButton} alt="Riversoft" />
                </a>
              </Text>
            </Box>
            <Box>
              <SectionPartners />
            </Box>
          </BoxColumns>
        </div>
      </FourthSection>

      <FifthSection>
        <div className="Content">
          {/* <BoxColumns widthFraction="1fr 2fr" style={{ marginBottom: "4rem" }}>
            <Box>
              <Text>
                <h2>Blog riversoft</h2>
                <p>
                  A gente não faz só transferência de arquivos, mas também
                  conhecimento e informação
                </p>
                <button className="secondaryButton">Ir para o blog</button>
              </Text>
            </Box>
            <Box>[carousel]</Box>
          </BoxColumns> */}

          <BoxColumns widthFraction="1fr 1fr">
            <Box className="box-contact-form">
              <form
                id="form-contact"
                onSubmit={handleSubmit(onSubmit)}
                ref={formElement}
              >
                <h3>Contato</h3>
                <p className="strong">
                  Ainda com alguma dúvida ou precisa falar com a gente?
                </p>
                <p>
                  Preencha o formulário abaixo e nós te retornaremos o mais
                  rápido possível.
                </p>

                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nome*"
                  {...register("name", {
                    required: true,
                    pattern: /^[A-Za-z ]+$/i,
                  })}
                />
                {errors.name?.type === "pattern" && (
                  <p className="error-text"> Não são aceitos números. </p>
                )}
                {errors.name?.type === "required" && (
                  <p className="error-text"> Não pode ser vazio. </p>
                )}
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-mail*"
                  {...register("email", { required: true })}
                />
                {errors.email?.type === "required" && (
                  <p className="error-text"> Não pode ser vazio. </p>
                )}
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  placeholder="Em que podemos ajudá-lo?"
                  {...register("description", { required: true })}
                ></textarea>
                {errors.description?.type === "required" && (
                  <p className="error-text"> Não pode ser vazio. </p>
                )}
                <input
                  type="hidden"
                  name="utm_source"
                  {...register("utm_source", { required: false })}
                  value={utmSourceRef}
                />
                <input
                  type="hidden"
                  name="identificador"
                  {...register("identificador", { required: false })}
                  value="contato-site"
                />
                <input
                  type="hidden"
                  name="utm_medium"
                  {...register("utm_medium", { required: false })}
                  value={utmMediumRef}
                />
                <input
                  type="hidden"
                  name="utm_campaign"
                  {...register("utm_campaign", { required: false })}
                  value={utmCampaignRef}
                />
                <input
                  type="hidden"
                  name="utm_term"
                  {...register("utm_term", { required: false })}
                  value={utmTermRef}
                />
                <input
                  type="hidden"
                  name="utm_content"
                  {...register("utm_content", { required: false })}
                  value={utmContentRef}
                />
                <input
                  type="hidden"
                  name="url_pagina"
                  {...register("url_pagina", { required: false })}
                  value={currentUrl}
                />
                <button type="submit" className="thirdButton">
                  Entrar em contato <img src={arrowButton} alt="Riversoft" />
                </button>
              </form>
            </Box>
            <Box width="fit-content">
              <div
                style={{ border: "9px solid var(--blue-dark)" }}
                dangerouslySetInnerHTML={map()}
              ></div>
              <p className="strong">Riversoft Integração e Inovação Ltda.</p>
              <address>
                R. Marechal Deodoro, 480 - 1º Andar <br />
                Santa Lúcia <br />
                Pouso Alegre MG <br />
                Brasil
              </address>
            </Box>
          </BoxColumns>
        </div>
      </FifthSection>
    </>
  );
}
